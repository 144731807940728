import { Collection, Database, Q } from '@nozbe/watermelondb';

import Contact from './Contact';
import Entry from './Entry';
import EntryProcedure from './EntryProcedure';
import Horse from './Horse';
import Organisation from './Organisation';
import Procedure from './Procedure';
import { EntryModel } from '../../types/Entries';
import { EntryProcedureModel } from '../../types/EntryProcedure';
import { InvoiceModel, InvoicePayload } from '../../types/Invoice';
import { InvoicesFiltersObject } from '../../types/invoicesFilters';
import {
    calculateInvoiceDetailsFromItems,
    generateReference,
} from '../../utils/invoicing';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import {
    applyInvoicesFiltersQueries,
    doesContactMatchSearchFilter,
    doesNamePartMatchSearchFilter,
} from '../utils';
import { getLocal, getNowISO } from '../../utils/date';
import { getProceduresFromEntriesIds } from '../../utils/procedures';
import { Queries } from '../../types/watermelonDb';
import { INVOICE_STATUS } from '../../constants/invoices/statuses';
import { ContactModel } from '../../types/Contacts';
import { OrganisationAccountingsModel } from '../../types/OrganisationAccountings';
import { FILTER_TYPE } from '../../types/filter';
import { AccountingProviderType } from 'shared/types/Invoicing';
import InvoiceInvoicingProduct from '../models/InvoiceInvoicingProduct';

class Invoice {
    private database: Database;
    private collection: Collection<InvoiceModel>;
    private table = 'invoices';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getCount() {
        return this.collection.query().fetchCount();
    }

    observeCount() {
        return this.collection.query().observeCount();
    }

    observeCountByProvider(provider: AccountingProviderType) {
        return this.collection
            .query(Q.where('provider', provider))
            .observeCount();
    }

    observeUnsyncedCount(provider: AccountingProviderType) {
        return this.collection
            .query(
                Q.where('provider', provider),
                Q.where('synced', Q.eq(false)),
            )
            .observeCount();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    private getInvoicesSearchLokiQuery(searchText: string) {
        const parsedSearchText = searchText.toLowerCase().trim();

        return [
            // Lack of types for Q.unsafeLokiTransform
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Q.unsafeLokiTransform((raws, loki) => {
                return raws.filter((rawRecord) => {
                    const contact = loki
                        .getCollection('contacts')
                        .by('id', rawRecord.contact_id);

                    if (contact) {
                        return (
                            doesContactMatchSearchFilter(
                                contact.first_name,
                                contact.last_name,
                                parsedSearchText,
                            ) ||
                            doesNamePartMatchSearchFilter(
                                contact.business_name ?? '',
                                searchText,
                            ) ||
                            doesNamePartMatchSearchFilter(
                                rawRecord.reference,
                                searchText,
                            )
                        );
                    } else {
                        doesNamePartMatchSearchFilter(
                            rawRecord.reference,
                            searchText,
                        );
                    }
                });
            }),
        ];
    }

    private getInvoicesSearchSQLQuery(searchText: string) {
        const parsedSearchText = searchText.toLowerCase().trim();

        const sanitizedSearch = Q.sanitizeLikeString(parsedSearchText);

        if (!sanitizedSearch.length) return;

        return [
            Q.experimentalJoinTables(['contacts']),
            Q.or(
                Q.on(
                    'contacts',
                    Q.or(
                        // @ts-ignore
                        Q.unsafeSqlExpr(
                            `first_name || ' ' || last_name LIKE '${sanitizedSearch}%'`,
                        ),
                        Q.where('last_name', Q.like(`${sanitizedSearch}%`)),
                        Q.where('business_name', Q.like(`${sanitizedSearch}%`)),
                        Q.where(
                            'business_name',
                            Q.like(`% ${sanitizedSearch}%`),
                        ),
                    ),
                ),
                Q.where('reference', Q.like(`${sanitizedSearch}%`)),
            ),
        ];
    }

    private getInvoicesFilterQueries(
        filters?: InvoicesFiltersObject,
        userHasProvider?: boolean,
    ) {
        const queries: Queries = [];

        if (filters) {
            queries.push(
                //@ts-ignore
                ...applyInvoicesFiltersQueries(filters, userHasProvider),
            );
        }

        return queries;
    }

    filterInvoices({
        searchText,
        filters,
        userHasProvider,
        filterType,
    }: {
        searchText: string;
        filters?: InvoicesFiltersObject;
        userHasProvider?: boolean;
        filterType: FILTER_TYPE;
    }) {
        const queries = this.getInvoicesFilterQueries(filters, userHasProvider);

        const searchQueries =
            filterType === FILTER_TYPE.LOKI
                ? this.getInvoicesSearchLokiQuery(searchText)
                : this.getInvoicesSearchSQLQuery(searchText);

        if (searchQueries) queries.push(...searchQueries);

        return this.collection
            .query(...queries)
            .extend(Q.sortBy('created_at', 'desc'));
    }

    getFilteredInvoicesCount(args: {
        searchText: string;
        filters?: InvoicesFiltersObject;
        userHasProvider?: boolean;
        filterType: FILTER_TYPE;
    }) {
        const query = this.filterInvoices(args);
        return query.fetchCount();
    }

    getFilteredInvoices(args: {
        searchText: string;
        filters?: InvoicesFiltersObject;
        userHasProvider?: boolean;
        filterType: FILTER_TYPE;
    }) {
        const query = this.filterInvoices(args);
        return query.fetch();
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    getByParams(params: { name: string; value: string }[]) {
        return this.collection.query(
            Q.and(...params.map((param) => Q.where(param.name, param.value))),
        );
    }

    fetchByParams(params: { name: string; value: string }[]) {
        return this.getByParams(params).fetch();
    }

    async bumpRelatedEntities(invoiceId: string) {
        const entryService = new Entry({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const entryProcedureService = new EntryProcedure({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const horseService = new Horse({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const contactService = new Contact({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const entries = await entryService.getByParam('invoice_id', invoiceId);
        const invoice = await this.getByID(invoiceId);
        const contact = invoice.contactId
            ? await contactService.getByID(invoice.contactId)
            : null;

        await entryService.bump(entries.map((e) => e.id));

        const entryProceduresToBump = await Promise.all(
            entries.map((e) =>
                entryProcedureService.getByParam('entry_id', e.id),
            ),
        );

        await entryProcedureService.bump(
            entryProceduresToBump.flatMap((e) => e).map((e) => e.id),
        );

        const horsesIdsToBump = entries
            .map((e) => e.horseId)
            // remove duplicates
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            });

        await horseService.bump(horsesIdsToBump);

        if (contact) {
            await contactService.updateField(
                contact.id,
                'firstName',
                contact.firstName,
            );
        }
    }

    async add(payload: InvoicePayload, userId: string) {
        const entryService = new Entry({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const contactService = new Contact({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const isInternalProvider = payload.provider === 'internal';

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        let entriesToUpdate: EntryModel[] = [];

        const procedures = await getProceduresFromEntriesIds({
            entriesIds: payload.selectedEntriesIds,
            options: {
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            },
        });

        return await this.database.write(async () => {
            const { subtotalSum, totalSum } =
                await calculateInvoiceDetailsFromItems({
                    procedures,
                    products: payload.selectedProducts.map((product) => ({
                        price: product.price,
                        quantity: product.quantity,
                    })),
                    taxRate: Number(payload.taxRate),
                    productsTaxRate: Number(payload.productsTaxRate),
                });

            const formattedTotal = totalSum.toFixed(2);
            const subtotalToCheck = subtotalSum.toFixed(2);

            // todo: Added for catching edge case where subtotal is missing. Delete later.
            if(!subtotalToCheck) {
                throw new Error('Subtotal is missing [invoice-add]');
            }

            const totalToCheck =
                payload.invoicingSystem && isInternalProvider
                    ? formattedTotal
                    : subtotalToCheck;

            const createdInvoice = await this.collection.create((invoice) => {
                invoice.number = payload.number || '';
                invoice.reference = generateReference();
                invoice.applyTaxes = payload.applyTaxes;
                invoice.status = payload.status;
                invoice.deepLink = payload.deepLink || '';
                invoice.publicURL = payload.publicURL || '';
                invoice.subTotal = !payload.applyTaxes
                    ? subtotalToCheck
                    : payload.subTotal || '';
                invoice.totalTax = payload.totalTax || '';
                invoice.total = payload.applyTaxes
                    ? totalToCheck
                    : payload.total || '';
                invoice.totalDiscount = payload.totalDiscount || '';
                invoice.sentToContact = payload.sentToContact || false;
                invoice.authorisedTime = payload.authorisedTime;
                invoice.invoiceDate = payload.invoiceDate || '';
                invoice.dueDateTime = payload.dueDateTime;
                invoice.sentToContactTime = payload.sentToContactTime || '';
                invoice.paymentDateTime = payload.paymentDateTime || '';
                invoice.contactId = payload.contactId;
                invoice.externalId = payload.externalId || '';
                invoice.attachReports = payload.attachReports;
                invoice.userId = userId;
                invoice.organisationId = organisationID;
                invoice.amountCheck = subtotalToCheck;
                invoice.footerText = payload.footerText || '';
                invoice.provider = payload.provider;
                invoice.taxRate = payload.taxRate;
                invoice.productsTaxRate = payload.productsTaxRate;
            });

            this.options.logDBAction({
                message: 'Created invoice',
                modelName: this.table,
                payload: createdInvoice,
            });

            await Promise.all(
                payload.selectedEntriesIds!.map(async (entryId) => {
                    const entry = await entryService.getByID(entryId.id);
                    const entryToUpdate = await entry.prepareUpdate((entry) => {
                        entry.invoiceId = createdInvoice.id;
                    });

                    entriesToUpdate = [...entriesToUpdate, entryToUpdate];
                }),
            );

            // Add products to the invoice
            await Promise.all(
                payload.selectedProducts.map(async (product) => {
                    await this.database.collections
                        .get('invoice_invoicing_products')
                        .create(
                            (
                                invoiceInvoicingProduct: InvoiceInvoicingProduct,
                            ) => {
                                invoiceInvoicingProduct.invoiceId =
                                    createdInvoice.id;
                                invoiceInvoicingProduct.invoicingProductId =
                                    product.invoicingProductId;
                                invoiceInvoicingProduct.quantity =
                                    product.quantity;
                                invoiceInvoicingProduct.userId = userId;
                                invoiceInvoicingProduct.organisationId =
                                    organisationID;
                                invoiceInvoicingProduct.price = product.price;
                                invoiceInvoicingProduct.name = product.name;
                            },
                        );
                }),
            );

            if (!!payload.contactId) {
                const contactElement = await contactService.getByID(
                    payload.contactId,
                );
                await contactElement.update(
                    (contact) => (contact.billable = true),
                );

                this.options.logDBAction({
                    message: 'Update contact field billable',
                    modelName: this.table,
                    payload: contactElement,
                });
            }

            await this.database.batch(...entriesToUpdate);

            this.options.logDBAction({
                message: 'Create invoice - update entries',
                modelName: this.table,
                payload: entriesToUpdate,
            });
            return createdInvoice;
        }, 'create-invoice');
    }

    async update(id: string, payload: Partial<InvoicePayload>) {
        const entryService = new Entry({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const invoiceProductsCollection =
            this.database.get<InvoiceInvoicingProduct>(
                'invoice_invoicing_products',
            );

        const initialProducts = invoiceProductsCollection
            ? await invoiceProductsCollection
                  .query(Q.where('invoice_id', id))
                  .fetch()
            : null;

        const invoiceElement = await this.getByID(id);
        const { selectedEntriesIds, selectedProducts } = payload;

        const updatedInvoice = await this.database.write(async () => {
            const entriesIds =
                selectedEntriesIds || (await invoiceElement.entries.fetch());
            const products = selectedProducts || initialProducts;

            const procedures = await getProceduresFromEntriesIds({
                entriesIds,
                options: {
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                },
            });

            const { subtotalSum, totalSum } =
                await calculateInvoiceDetailsFromItems({
                    procedures,
                    products: products?.map((product) => ({
                        price: product.price,
                        quantity: product.quantity,
                    })),
                    taxRate: Number(payload.taxRate || invoiceElement.taxRate),
                    productsTaxRate: Number(
                        payload.productsTaxRate ||
                            invoiceElement.productsTaxRate,
                    ),
                });

            const totalToCheck = totalSum.toFixed(2);
            const subtotalToCheck = subtotalSum.toFixed(2);

            // todo: Added for catching edge case where subtotal is missing. Delete later.
            if(!subtotalToCheck) {
                throw new Error('Subtotal is missing [invoice-update]');
            }

            const updatedInvoiceElement = await invoiceElement.update(
                (invoice) => {
                    invoice.number = payload.number || invoiceElement.number;
                    invoice.reference =
                        payload.reference || invoiceElement.reference;
                    invoice.applyTaxes =
                        typeof payload.applyTaxes == 'boolean'
                            ? payload.applyTaxes
                            : invoiceElement.applyTaxes;
                    invoice.status = payload.status || invoiceElement.status;
                    invoice.deepLink =
                        payload.deepLink || invoiceElement.deepLink;
                    invoice.publicURL =
                        payload.publicURL || invoiceElement.publicURL;

                    if (payload.invoicingSystem) {
                        invoice.subTotal = !payload.applyTaxes
                            ? subtotalToCheck
                            : invoiceElement.subTotal;
                        invoice.total = payload.applyTaxes
                            ? totalToCheck
                            : invoiceElement.totalTax;
                    } else {
                        invoice.subTotal =
                            payload.subTotal || invoiceElement.subTotal;
                        invoice.totalTax =
                            payload.totalTax || invoiceElement.totalTax;
                    }

                    invoice.total = payload.total || invoiceElement.total;
                    invoice.totalDiscount =
                        payload.totalDiscount || invoiceElement.totalDiscount;
                    invoice.sentToContact =
                        payload.sentToContact || invoiceElement.sentToContact;
                    invoice.authorisedTime =
                        payload.authorisedTime || invoiceElement.authorisedTime;
                    invoice.dueDateTime =
                        payload.dueDateTime || invoiceElement.dueDateTime;
                    invoice.invoiceDate =
                        payload.invoiceDate || invoiceElement.invoiceDate;
                    invoice.sentToContactTime =
                        payload.sentToContactTime ||
                        invoiceElement.sentToContactTime;
                    invoice.paymentDateTime =
                        payload.paymentDateTime ||
                        invoiceElement.paymentDateTime;
                    invoice.attachReports =
                        payload.attachReports !== undefined
                            ? payload.attachReports
                            : invoiceElement.attachReports;
                    invoice.contactId =
                        payload.contactId || invoiceElement.contactId;
                    invoice.externalId =
                        payload.externalId || invoiceElement.externalId;
                    invoice.userId = invoiceElement.userId;
                    invoice.organisationId = invoiceElement.organisationId;
                    invoice.amountCheck = subtotalToCheck;
                    invoice.footerText = payload.footerText || '';
                    invoice.taxRate = payload.taxRate || invoiceElement.taxRate;
                    invoice.productsTaxRate =
                        payload.productsTaxRate ||
                        invoiceElement.productsTaxRate;
                },
            );

            this.options.logDBAction({
                message: 'Update invoice',
                modelName: this.table,
                payload: invoiceElement,
            });

            const initialEntries = await invoiceElement.entries.fetch();
            let entriesToUpdate: EntryModel[] = [];

            if (!!selectedEntriesIds) {
                await Promise.all(
                    selectedEntriesIds.map(async (invoiceEntry) => {
                        const initialEntry = initialEntries.find(
                            (initialEntry) =>
                                initialEntry.id === invoiceEntry.id,
                        );

                        if (!initialEntry) {
                            const entry = await entryService.getByID(
                                invoiceEntry.id,
                            );
                            const entryToUpdate = await entry.prepareUpdate(
                                (entry) => {
                                    entry.invoiceId = invoiceElement.id;
                                },
                            );

                            entriesToUpdate = [
                                ...entriesToUpdate,
                                entryToUpdate,
                            ];
                        }
                    }),
                );

                await Promise.all(
                    initialEntries.map(async (initialEntry) => {
                        if (
                            !selectedEntriesIds.find(
                                (invoiceEntry) =>
                                    invoiceEntry.id === initialEntry.id,
                            )
                        ) {
                            const entry = await entryService.getByID(
                                initialEntry.id,
                            );
                            const entryToUpdate = await entry.prepareUpdate(
                                (entry) => {
                                    entry.invoiceId = null;
                                },
                            );

                            entriesToUpdate = [
                                ...entriesToUpdate,
                                entryToUpdate,
                            ];
                        }
                    }),
                );
            }

            // Update products in the invoice

            let productsToUpdate: InvoiceInvoicingProduct[] = [];
            let productsToDelete: InvoiceInvoicingProduct[] = [];

            if (selectedProducts) {
                await Promise.all(
                    selectedProducts.map(async (product) => {
                        const initialProduct = initialProducts?.find(
                            (ip: InvoiceInvoicingProduct) =>
                                ip.invoicingProductId ===
                                product.invoicingProductId,
                        );

                        if (!initialProduct) {
                            // Add new product to invoice
                            const newInvoiceProduct =
                                await this.database.collections
                                    .get<InvoiceInvoicingProduct>(
                                        'invoice_invoicing_products',
                                    )
                                    .prepareCreate(
                                        (
                                            invoiceInvoicingProduct: InvoiceInvoicingProduct,
                                        ) => {
                                            invoiceInvoicingProduct.invoiceId =
                                                id;
                                            invoiceInvoicingProduct.invoicingProductId =
                                                product.invoicingProductId;
                                            invoiceInvoicingProduct.quantity =
                                                product.quantity;
                                            invoiceInvoicingProduct.userId =
                                                invoiceElement.userId;
                                            invoiceInvoicingProduct.organisationId =
                                                invoiceElement.organisationId;
                                            invoiceInvoicingProduct.price =
                                                product.price;
                                            invoiceInvoicingProduct.name =
                                                product.name;
                                        },
                                    );
                            productsToUpdate.push(newInvoiceProduct);
                        } else {
                            // Update existing product quantity
                            const productToUpdate =
                                initialProduct.prepareUpdate(
                                    (p: InvoiceInvoicingProduct) => {
                                        p.quantity = product.quantity;
                                    },
                                );
                            productsToUpdate.push(productToUpdate);
                        }
                    }),
                );

                if (initialProducts) {
                    await Promise.all(
                        initialProducts.map(
                            async (initialProduct: InvoiceInvoicingProduct) => {
                                if (
                                    !selectedProducts.find(
                                        (product) =>
                                            product.invoicingProductId ===
                                            initialProduct.invoicingProductId,
                                    )
                                ) {
                                    // Remove product from invoice
                                    const productToDelete =
                                        initialProduct.prepareMarkAsDeleted();
                                    productsToDelete.push(productToDelete);
                                }
                            },
                        ),
                    );
                }
            }

            await this.database.batch(
                ...entriesToUpdate,
                ...productsToUpdate,
                ...productsToDelete,
            );

            this.options.logDBAction({
                message: 'Update invoice - update entries',
                modelName: this.table,
                payload: entriesToUpdate,
            });

            return updatedInvoiceElement;
        }, 'update-invoice');

        return updatedInvoice;
    }

    async deleteByID(id: string) {
        const invoiceElement = await this.getByID(id);
        const entries = await invoiceElement.entries.fetch();

        const procedureService = new Procedure({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        let entriesToUpdate: EntryModel[] = [];
        let entryProceduresToUpdate: EntryProcedureModel[] = [];

        await this.database.write(async () => {
            await invoiceElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete invoice',
                modelName: this.table,
                payload: invoiceElement,
            });

            if (entries.length > 0) {
                await Promise.all(
                    entries.map(async (entry) => {
                        const entryToUpdate = await entry.prepareUpdate(
                            (entry) => {
                                entry.invoiceId = null;
                            },
                        );

                        entriesToUpdate = [...entriesToUpdate, entryToUpdate];

                        const entryProcedures =
                            await entry.entryProcedures.fetch();

                        await Promise.all(
                            entryProcedures.map(async (entryProc) => {
                                const procedure =
                                    await procedureService.getByID(
                                        entryProc.procedureId,
                                    );

                                const entryProcToUpdate =
                                    await entryProc.prepareUpdate((ep) => {
                                        ep.name = procedure.name;
                                        ep.price = procedure.price ?? '';
                                    });

                                entryProceduresToUpdate = [
                                    ...entryProceduresToUpdate,
                                    entryProcToUpdate,
                                ];
                            }),
                        );
                    }),
                );
            }

            this.database.batch(...entriesToUpdate, ...entryProceduresToUpdate);

            this.options.logDBAction({
                message: 'Delete invoice - update entries',
                modelName: this.table,
                payload: entriesToUpdate,
            });
            this.options.logDBAction({
                message: 'Delete invoice - update entry procedures',
                modelName: this.table,
                payload: entryProceduresToUpdate,
            });
        });
    }

    getByStatusAndProvider({
        status,
        provider,
    }: {
        status: INVOICE_STATUS[];
        provider?: AccountingProviderType;
    }) {
        let queries = [Q.where('status', Q.oneOf(status))];

        if (provider) {
            queries.push(Q.where('provider', provider));
        }

        return this.collection.query(queries);
    }

    getAwaitingPaymentInvoices(provider?: AccountingProviderType) {
        return this.getByStatusAndProvider({
            status: [INVOICE_STATUS.authorised, INVOICE_STATUS.sent],
            provider,
        });
    }

    getDraftInvoices(provider?: AccountingProviderType) {
        return this.getByStatusAndProvider({
            status: [INVOICE_STATUS.draft],
            provider,
        });
    }

    getPaidInvoices(provider?: AccountingProviderType) {
        return this.getByStatusAndProvider({
            status: [INVOICE_STATUS.paid],
            provider,
        });
    }

    getOverdueInvoices(provider?: AccountingProviderType) {
        const today = getLocal();
        const formattedToday = Q.sanitizeLikeString(
            today.toISO().split('T')[0],
        ).replace(/_/g, '-');

        let queries = [
            Q.where(
                'status',
                Q.oneOf([INVOICE_STATUS.authorised, INVOICE_STATUS.sent]),
            ),
            Q.where('due_date_time', Q.lt(formattedToday)),
        ];

        if (provider) {
            queries.push(Q.where('provider', provider));
        }

        return this.collection.query(Q.and(queries));
    }

    async getArchivedInvoicesDetails(providers: AccountingProviderType[]) {
        const results: Partial<
            Record<
                AccountingProviderType,
                {
                    count: number;
                    notSynced: number;
                    lastUsedAt?: Date;
                    disconnectedAt?: Date;
                    accountId: string;
                }
            >
        > = {};

        for (const provider of providers) {
            if (provider === 'internal') {
                const invoices = await this.collection
                    .query(
                        Q.where('provider', provider),
                        Q.sortBy('updated_at', 'desc'),
                    )
                    .fetch();

                if (invoices.length) {
                    results[provider] = {
                        count: invoices.length,
                        notSynced: 0,
                        lastUsedAt: invoices[0].updatedAt,
                        accountId: 'internal',
                    };
                }
            } else {
                const invoices = await this.collection
                    .query(
                        Q.where('provider', provider),
                        Q.where('provider_archived_at', Q.notEq(null)),
                        Q.sortBy('provider_archived_at', 'desc'),
                    )
                    .fetch();

                const notSynced = await this.collection
                    .query(
                        Q.where('provider', provider),
                        Q.where('synced', Q.eq(false)),
                    )
                    .fetchCount();

                if (invoices.length) {
                    results[provider] = {
                        count: invoices.length,
                        notSynced,
                        disconnectedAt: invoices[0].providerArchivedAt
                            ? new Date(invoices[0].providerArchivedAt)
                            : undefined,
                        accountId: invoices[0].userProviderEmail || '',
                    };
                }
            }
        }

        return results;
    }

    async authoriseInvoice({
        invoice,
        contact,
        hasAccountingProvider,
    }: {
        invoice: InvoiceModel;
        contact?: ContactModel | null;
        hasAccountingProvider: boolean;
    }) {
        if (contact?.email && hasAccountingProvider) {
            await this.update(invoice.id, {
                status: INVOICE_STATUS.authorised,
                sentToContact: !!contact.email,
                authorisedTime: getNowISO(),
                sentToContactTime: getNowISO(),
            });
        } else {
            await this.update(invoice.id, {
                status: INVOICE_STATUS.authorised,
                authorisedTime: getNowISO(),
            });
        }
    }

    setInvoiceToPaid(invoice: InvoiceModel) {
        return this.update(invoice.id, {
            status: INVOICE_STATUS.paid,
        });
    }

    async sendInvoice(
        invoice: InvoiceModel,
        accountings: OrganisationAccountingsModel | null,
    ) {
        if (!accountings?.accountingProvider) return;

        const payload = { sentToContactTime: getNowISO() };

        if (
            invoice.status !== INVOICE_STATUS.authorised &&
            invoice.status !== INVOICE_STATUS.paid
        ) {
            payload['status'] = INVOICE_STATUS.authorised;
            payload['authorisedTime'] = getNowISO();
        }

        await this.update(invoice.id, payload);
    }

    getInvoicesUnsyncedWithProvider() {
        return this.collection.query(
            Q.or(Q.where('external_id', ''), Q.where('external_id', null)),
        );
    }

    getLastProviderSyncedInvoice(provider) {
        return this.collection.query(
            Q.where('provider', provider),
            Q.sortBy('last_synced_at', Q.desc),
            Q.take(1),
        );
    }

    getUnsyncedActiveInvoices(provider: AccountingProviderType) {
        return this.collection.query(
            Q.where('provider', provider),
            Q.where('synced', Q.eq(false)),
        );
    }

    getProviderTaxedDraftInvoices(provider: AccountingProviderType) {
        return this.collection.query(
            Q.where('provider', provider),
            Q.where('status', INVOICE_STATUS.draft),
            Q.where('apply_taxes', true),
        );
    }
}

export default Invoice;
